import React from "react";
import { Carousel, Container, Row, Image} from "react-bootstrap";
import LayoutIndex from "../components/layout-index";
import Seo from "../components/seo";
import BannerUtilidades from "../components/banner-utilidades"
import Logo from "../images/bannerLogo.png"
import BannerConfianza from "../components/banner-confianza";
import BannerProyecto from "../components/banner-proyecto";

const IndexPage = () => (
  <LayoutIndex>
    <Seo title="Principal" description="Pagina Principal CoopGestión"  />
    <Carousel>
      <Carousel.Item>
        <BannerUtilidades></BannerUtilidades>
      </Carousel.Item>
      <Carousel.Item>
        <BannerConfianza></BannerConfianza>
      </Carousel.Item>
      <Carousel.Item>
        <BannerProyecto></BannerProyecto>
      </Carousel.Item>
    </Carousel>
    <Container>
      <Row className="my-5 justify-content-md-center">
          <div className="text-center">
            <Image src={Logo} alt="CoopGestión" fluid/>
          </div>
      </Row>
    </Container>
  </LayoutIndex>
);

export default IndexPage;
