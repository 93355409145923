import React from "react";
import Header from "./header";
import "./layout.scss";
import Footer from "./footer";

const LayoutIndex = ({ children }) => {

  return (
    <>
      <Header/>
      <main>
        {children}
      </main>
      <Footer noFooterLogos="false"/>
    </>
  );
};

export default LayoutIndex;
